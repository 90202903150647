/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states(
  $stateProvider,
  nsUtilProvider,
  NS_ACCOUNT_TYPES,
  NS_PROGRAM_TYPES
) {
  $stateProvider
    // Simple redirect to go to the currently selected account statements
    .state('statements-selected-account', {
      url: '/statements-selected-account',
      params: {
        popup: {
          value: false
        }
      },
      data: {
        permissions: {
          only: ['failure'],
          redirectTo: {
            failure: /* @ngInject */ function(
              acpCoreDispatcher,
              acpAccountDomainModel,
              acpPopup,
              nsPermissions,
              $state,
              transitionProperties
            ) {
              var selectedAccount = acpAccountDomainModel.selected.getValue();
              var permissions = nsPermissions.permissions.getValue();
              var programType = acpCoreDispatcher.account.programType.getValue();
              var newState = 'statements';
              var newParams = {
                account_id: selectedAccount.id,
                subaccount: selectedAccount.subaccount === true,
                subacct_enabled: permissions.subaccountEnabledAccount,
                type: selectedAccount.type,
                program_type: programType
              };

              if (transitionProperties.toParams.popup) {
                // Open in a popup and do not go anywhere else on current page
                acpPopup($state.href(newState, newParams));
                return null;
              }

              return {
                state: newState,
                params: newParams
              };
            }
          }
        }
      }
    })
    .state('statements', {
      url:
        '/statements/:account_id/:subaccount/:subacct_enabled/:type/:program_type',
      templateProvider: function(
        $stateParams,
        $templateFactory,
        acpBrandingModel
      ) {
        var brand = acpBrandingModel.getBranding();
        switch ($stateParams.type) {
          // Both CONSUMER_DDA and BAW_DDA receive "dda" account types.
          case NS_ACCOUNT_TYPES.DDA: {
            if (brand && brand.id === 'westernunionaa') {
              return $templateFactory.fromUrl(
                'apps/statements/templates/acp-statements-wu-dda.ng.html'
              );
            }
            return $templateFactory.fromUrl(
              'apps/statements/templates/acp-statements-dda.ng.html'
            );
          }
          case NS_ACCOUNT_TYPES.SAVINGS: {
            if (
              $stateParams.program_type === NS_PROGRAM_TYPES.CONSUMER_DDA ||
              $stateParams.program_type === NS_PROGRAM_TYPES.BAW_DDA
            ) {
              if (brand && brand.id === 'westernunionaa') {
                return $templateFactory.fromUrl(
                  'apps/statements/templates/acp-statements-wu-dda-savings.ng.html'
                );
              }
              if (brand && brand.id === 'azulos_plus_cdda') {
                return $templateFactory.fromUrl(
                  'apps/statements/templates/acp-statements-azulos-plus-dda-savings.ng.html'
                );
              }
              return $templateFactory.fromUrl(
                'apps/statements/templates/acp-statements-dda-savings.ng.html'
              );
            }
          }
          /* falls through */
          case NS_ACCOUNT_TYPES.DEBIT: {
            if (
              $stateParams.program_type === NS_PROGRAM_TYPES.GPR &&
              $stateParams.subacct_enabled === 'true'
            ) {
              return $templateFactory.fromUrl(
                'apps/statements/templates/acp-statements-gpr-plus-subs.ng.html'
              );
            }
          }
          /* falls through */
          default: {
            return $templateFactory.fromUrl(
              'apps/statements/templates/acp-statements.ng.html'
            );
          }
        }
      },
      resolve: {
        pageTitle: /* @ngInject */ function(module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/statements')
          );
        },
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.statements" */ 'apps/statements').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('statements-select-mobile', {
      url: '/statements-select-mobile',
      data: {
        permissions: {
          only: ['failure'],
          redirectTo: {
            failure: /* @ngInject */ function(
              acpCoreDispatcher,
              acpAccountDomainModel,
              nsPermissions
            ) {
              var selectedAccount = acpAccountDomainModel.selected.getValue();
              var permissions = nsPermissions.permissions.getValue();
              var programType = acpCoreDispatcher.account.programType.getValue();
              var newState = 'statements-mobile';
              var newParams = {
                account_id: selectedAccount.id,
                subaccount: selectedAccount.subaccount === true,
                subacct_enabled: permissions.subaccountEnabledAccount,
                type: selectedAccount.type,
                program_type: programType
              };
              return {
                state: newState,
                params: newParams
              };
            }
          }
        }
      }
    })
    .state('statements-mobile', {
      parent: 'root',
      url:
        '/statements-mobile/:account_id/:subaccount/:subacct_enabled/:type/:program_type',
      templateProvider: function(
        $stateParams,
        $templateFactory,
        acpBrandingModel
      ) {
        var brand = acpBrandingModel.getBranding();
        switch ($stateParams.type) {
          // Both CONSUMER_DDA and BAW_DDA receive "dda" account types.
          case NS_ACCOUNT_TYPES.DDA: {
            if (brand && brand.id === 'westernunionaa') {
              return $templateFactory.fromUrl(
                'apps/statements/templates/acp-statements-wu-dda.ng.html'
              );
            }
            return $templateFactory.fromUrl(
              'apps/statements/templates/acp-statements-dda.ng.html'
            );
          }
          case NS_ACCOUNT_TYPES.SAVINGS: {
            if (
              $stateParams.program_type === NS_PROGRAM_TYPES.CONSUMER_DDA ||
              $stateParams.program_type === NS_PROGRAM_TYPES.BAW_DDA
            ) {
              if (brand && brand.id === 'westernunionaa') {
                return $templateFactory.fromUrl(
                  'apps/statements/templates/acp-statements-wu-dda-savings.ng.html'
                );
              }
              if (brand && brand.id === 'azulos_plus_cdda') {
                return $templateFactory.fromUrl(
                  'apps/statements/templates/acp-statements-azulos-plus-dda-savings.ng.html'
                );
              }
              return $templateFactory.fromUrl(
                'apps/statements/templates/acp-statements-dda-savings.ng.html'
              );
            }
          }
          /* falls through */
          case NS_ACCOUNT_TYPES.DEBIT: {
            if (
              $stateParams.program_type === NS_PROGRAM_TYPES.GPR &&
              $stateParams.subacct_enabled === 'true'
            ) {
              return $templateFactory.fromUrl(
                'apps/statements/templates/acp-statements-gpr-plus-subs.ng.html'
              );
            }
          }
          /* falls through */
          default: {
            return $templateFactory.fromUrl(
              'apps/statements/templates/acp-statements.ng.html'
            );
          }
        }
      },
      resolve: {
        pageTitle: /* @ngInject */ function(module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/statements')
          );
        },
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.statements" */ 'apps/statements').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    });
}

export default states;
